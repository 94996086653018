import { CreateUpdateMooringComponent, MooringComponent } from './MooringComponent';

class BaseMooringBridle {
  MoorlogNo: string;
  AnalysisNo: string;
  DimForce?: number;
  IsAccident: boolean;

  BuoyId: number;
  BuoyNo?: string;

  CageId: number;
  CageNo?: string;

  MinMblRopesStraps?: number;
  MinMblChainShackleConnectionPlates?: number;
  MinMblAnchoring?: number;
}

export class UpdateMooringBridleDto extends BaseMooringBridle {
  Id: number;
  Components: CreateUpdateMooringComponent[];
}

export class AddMooringBridleDto {
  MoorlogNo: string;
  AnalysisNo: string;
  BuoyId: number;
  CageId: number;
}

export class MooringBridle extends BaseMooringBridle {
  Id: number;
  Components: MooringComponent[];
  HasUnresolvedComments: boolean;
  HasUnresolvedComponentComments: boolean;
  HasComments: boolean;
}

import { CreateUpdateMooringComponent, MooringComponent } from './MooringComponent';

class BaseMooringGridLine {
  MoorlogNo: string;
  AnalysisNo: string;
  DimForce?: number;

  StartBuoyId: number;
  EndBuoyId: number;

  IsAccident: boolean;
  MinMblRopesStraps?: number;
  MinMblChainShackleConnectionPlates?: number;
  MinMblAnchoring?: number;

  Length: number;
  SplicedLength: number;
}

export class UpdateMooringGridLineDto extends BaseMooringGridLine {
  Id: number;
  Components: CreateUpdateMooringComponent[];
}

export class AddMooringGridLineDto {
  MoorlogNo: string;
  AnalysisNo: string;
  StartBuoyId: number;
  EndBuoyId: number;
}

export class MooringGridLine extends BaseMooringGridLine {
  Id: number;
  Components: MooringComponent[];

  StartBuoyNo: string;
  EndBuoyNo: string;

  HasUnresolvedComments: boolean;
  HasUnresolvedComponentComments: boolean;
  HasComments: boolean;
}

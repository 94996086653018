export class ElementEntry {
  Id: number;
  Name: string;
}

export class GridLineElementEntry extends ElementEntry {
  StartBuoyNo: string;
  EndBuoyNo: string;
}

export class AnchorLineElementEntry extends ElementEntry {
  buoyNo: string;
}

export class BridleElementEntry extends ElementEntry {
  BuoyNo: string;
  CageNo: string;
}

export class MooringElementsListDto {
  AnchorLines: AnchorLineElementEntry[];

  GridLines: GridLineElementEntry[];

  Buoys: ElementEntry[];

  Bridles: BridleElementEntry[];

  Cages: ElementEntry[];
}

export const MooringComponentType = {
  anchorLine: 'anchorLine',
  gridLine: 'gridLine',
  buoy: 'buoy',
  bridle: 'bridle',
  cage: 'cage',
} as const;

export type MooringComponentType = (typeof MooringComponentType)[keyof typeof MooringComponentType];

export class MooringComponent {
  Id: number;
  ArticleId: number;
  Quantity: number;
  HasLoadConstraint: boolean;
  UtilizationFactor?: number;
  ProductName: string;
  CategoryName: string;
  TypeName: string;
  ProductTypeName?: string;
  MBL?: number;
  WLL?: number;
  SortIndex: number;
}

export class CreateUpdateMooringComponent {
  Id?: number;
  ArticleId: number;
  SortIndex: number;
}

export class MooringDrawingSetup {
  MooringId!: number;

  CenterPositionLat?: string;
  CenterPositionLong?: string;

  Cages: CageDrawingPropertyDto[];
  Buoys: BuoyDrawingPropertyDto[];
  GridLines: GridLineDrawingPropertyDto[];
  AnchorLines: AnchorLineDrawingPropertyDto[];
  Bridles: BridleDrawingPropertyDto[];
}

export class CageDrawingPropertyDto {
  Id: number;
  MooringNo: string;
  AnalysisNo: string;
  Column: number;
  Row: number;
}

export class BuoyDrawingPropertyDto {
  Id: number;
  MooringNo: string;
  AnalysisNo: string;
  Column: number;
  Row: number;
}

export class GridLineDrawingPropertyDto {
  Id: number;
  MooringNo: string;
  AnalysisNo: string;
  IsAccident: boolean;
  Buoys: number[];
}

export class AnchorLineDrawingPropertyDto {
  Id: number;
  MooringNo: string;
  AnalysisNo: string;
  IsAccident: boolean;
  BuoyId: number;
}

export class BridleDrawingPropertyDto {
  Id: number;
  MooringNo: string;
  AnalysisNo: string;
  BuoyId: number;
  CageId: number;
}

import { CreateUpdateMooringComponent, MooringComponent } from './MooringComponent';

class BaseMooringBuoy {
  MoorlogNo: string;
  AnalysisNo: string;
  SizeKg: number;
  Row: number;
  Column: number;
  PullDownKg: number;
  PullDownMeter: number;
  ResidualPullDownMeter: number;
  ResidualDriftKg: number;
}

export class UpdateMooringBuoyDto extends BaseMooringBuoy {
  Id: number;
  Components: CreateUpdateMooringComponent[];
}
export class AddMooringBuoyDto {
  MoorlogNo: string;
  AnalysisNo: string;
  StartGridLineId: number;
  EndGridLineId: number;
}

export class MooringBuoy extends BaseMooringBuoy {
  Id: number;
  Components: MooringComponent[];
  HasUnresolvedComments: boolean;
  HasUnresolvedComponentComments: boolean;
  HasComments: boolean;
}
